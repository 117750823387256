import colors from '../../themes/main-colors';
import { Theme, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        flexDirection: 'row',
        display: 'flex',
      },
      '& .MuiFormControl-root': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& .MuiGrid-justify-xs-center': {
        justifyContent: 'flex-start',
      },
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    alertText: {
      marginTop: '1em',
      '& > p': {
        marginTop: '0.5em',
        marginBottom: '0.5em',
      },
    },
    submit: {
      marginTop: '10px',
      margin: theme.spacing(3, 0, 2),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 'auto',
      },
    },
    specialHandlingText: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  }),
);

export default useStyles;
