import React from 'react';

import AuthLayout from '../../components/auth-layout';
import SpecialHandling from '../../components/special-handling';
import { useTranslation } from '../../hooks/useTranslation';

export default () => {
  const { t } = useTranslation();
  const title = t('FORGOT_PASSWORD');

  return (
    <AuthLayout title={title} showLeft={false} footer={'  '}>
      <SpecialHandling />
    </AuthLayout>
  );
};
